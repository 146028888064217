import type { ReactElement, ReactNode } from 'react';
import { AjaxErrorAlert, LoadingAlert, useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import orderBy from 'lodash/orderBy';
import { atom, useAtom, useAtomValue } from 'jotai';
import type { AppSettingsVDData, AppSettingsVDItem, GetValueDomainValues } from '../types';

export interface AppSettings {
    bannerHTML?: string;
    datauttag_prototyp_access: {
        region_id?: number;
        role_id?: number;
        usernames?: string[];
    }[];
    prom_name_map: Record<string, string>;
    default_shiny_minheight: number;
    etjanster_url: string;
    svar_url: string;

    patientPortal_overview_message: string;
    report_infotexts: Record<string, string | undefined>;
    manualUrlFallback?: string;
    hiddenStartPageTabs: string[];
    sourceSystemNameMap: Record<string, string>;
    open_on_startpage: boolean;
    substances_atc_regexps: RegExp[];
    substances_nsl_ids: string[];
}

export interface AppSettingsLoaderProps {
    vdname: string;
    children: ReactNode;
}

export const appSettingsAtom = atom<AppSettings | null>(null);

export const getAppSettings = (items: Readonly<AppSettingsVDItem[]>, hostname: string, env: string): Record<string, string> => {
    const sortedItems = orderBy([...items], [x => x.data.hostname === hostname, x => x.data.environment === env], ['desc', 'desc']);

    return sortedItems.reduce<Record<string, string>>((red, item) => {
        // Eftersom de är sorterade räcker det att den första tas.
        if (!Object.prototype.hasOwnProperty.call(red, item.data.key)) {
            red[item.data.key] = item.data.value;
        }

        return red;
    }, {});
};

export async function getAppSettings2(opts: {
    getValueDomainValues: GetValueDomainValues;
    vdname: string;
    hostname: string;
    env: string;
}): Promise<AppSettings> {
    const { getValueDomainValues, vdname, hostname, env } = opts;
    const vddata = await getValueDomainValues<AppSettingsVDData>(vdname, {
        parameters: {
            app: ['inca_oversikt'],
            env: [env],
            hostname: [hostname],
        },
    });
    const appdata = getAppSettings(vddata, hostname, env);

    return {
        bannerHTML: appdata.banner_html || '',
        datauttag_prototyp_access: JSON.parse(appdata.datauttag_prototyp_access || '[]'),
        prom_name_map: JSON.parse(appdata.prom_name_map || '{}'),
        default_shiny_minheight: Number.parseInt(appdata.default_shiny_minheight, 10),
        etjanster_url: appdata.etjanster_url,
        svar_url: appdata.svar_url,
        patientPortal_overview_message: appdata.patientportal_overview_message,
        report_infotexts: JSON.parse(appdata.report_infotexts || '{}'),
        manualUrlFallback: appdata.manual_url_fallback || undefined,
        hiddenStartPageTabs: JSON.parse(appdata.hidden_startpage_tabs || '[]'),
        sourceSystemNameMap: JSON.parse(appdata.sourcesystemname_map || '{}'),
        open_on_startpage: appdata.open_on_startpage === 'true',
        substances_atc_regexps: JSON.parse(appdata.substances_atc_regexps || '[]').map((x: string) => new RegExp(x)),
        substances_nsl_ids: JSON.parse(appdata.substances_nsl_ids || '[]'),
    } satisfies AppSettings;
}

export const useAppSettings = (): AppSettings => {
    const ctx = useAtomValue(appSettingsAtom);
    if (!ctx) {
        throw new Error('AppSettings must be initialized.');
    }

    return ctx;
};

/**
 * Load app settings (from the general register 'AppSettings'). They can be accessed with the hook useAppSettings.
 */
export const AppSettingsLoader = (props: AppSettingsLoaderProps): ReactElement => {
    const { vdname, children } = props;
    const getValueDomainValues = useGetValueDomainValues();
    const env = process.env.RCC_ENV || 'development';
    const { hostname } = location;
    const [appSettings, setAppSettings] = useAtom(appSettingsAtom);
    const { isLoading, error, result } = usePromise<AppSettings>(async () => {
        const settings = await getAppSettings2({
            getValueDomainValues,
            vdname,
            hostname,
            env,
        });

        setAppSettings(settings);

        return settings;
    });

    if (error) {
        return <AjaxErrorAlert error={error} />;
    }

    if (isLoading || !result || !appSettings) {
        return <LoadingAlert />;
    }

    return (
        <>{children}</>
    );
};
