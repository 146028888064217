import type { ReactElement } from 'react';
import type { DataSet, FormConfigProps } from '../types';

export default (configName: string): Promise<{
    dataset: DataSet;
    default: (props: FormConfigProps) => ReactElement;
}> => {
    return import(
        /* webpackChunkName: "config-form-[request]" */
        /* webpackInclude: /index\.(js|tsx)/ */
        /* webpackExclude: /textextraktion/ */
        `../configs/${configName}`); // Include index to prevent webpack from generating chunks for other files in the folder, e.g. tests and snapshots.
};
