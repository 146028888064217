import type { OverviewOptions } from '../types';

export interface CustomIpoEventMap {
    'ipo:overview_closed': {
        registerRecordId: number;
    };
    'ipo:open_overview': {
        registerRecordId: number;
        options: OverviewOptions;
    };
    'ipo:navigate_to_rr': {
        registerRecordId: number;
        shiftKey?: boolean;
        ctrlKey?: boolean;
        linkTarget?: string;
        options: OverviewOptions;
    };
    'ipo:notification': {
        type: 'success' | 'danger';
        message: string;
    };
}

export interface CustomIpoEventHandler {
    'ipo:overview_closed': (this: Document, ev: CustomEvent<CustomIpoEventMap['ipo:overview_closed']>) => void;
    'ipo:open_overview': (this: Document, ev: CustomEvent<CustomIpoEventMap['ipo:open_overview']>) => void;
    'ipo:navigate_to_rr': (this: Document, ev: CustomEvent<CustomIpoEventMap['ipo:navigate_to_rr']>) => void;
    'ipo:notification': (this: Document, ev: CustomEvent<CustomIpoEventMap['ipo:notification']>) => void;
}

export const addIpoEventListener = <T extends keyof CustomIpoEventMap>(eventName: T, handler: (this: Document, ev: CustomEvent<CustomIpoEventMap[T]>) => void): void => {
    document.addEventListener(eventName, handler as any, false);
};

export const removeIpoEventListener = <T extends keyof CustomIpoEventMap>(eventName: T, handler: (this: Document, ev: CustomEvent<CustomIpoEventMap[T]>) => void): void => {
    document.removeEventListener(eventName, handler as any);
};

export const dispatchIpoEvent = <T extends keyof CustomIpoEventMap>(eventName: T, data: CustomIpoEventMap[T]): void => {
    document.dispatchEvent(new CustomEvent<CustomIpoEventMap[T]>(eventName, { detail: data }));
};
