import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useHTabs } from './useHTabs';

const HTab = (props: {
    eventKey: string;
    header: ReactNode;
    children: ReactNode;
    hide?: boolean;
}): null => {
    const { eventKey, header, children, hide } = props;
    const { renderTab, removeTab } = useHTabs(eventKey);

    useEffect(() => {
        return () => {
            removeTab();
        };
    }, [removeTab]);

    useEffect(() => {
        renderTab({ header, content: children, hide });
    });

    return null;
};

export default HTab;
