import { useEffect } from 'react';
import { dataUtils, useIncaForm } from '@cancercentrum/rcc-react';
import { format } from 'date-fns';
import { useGlobalInca } from '../components/GlobalIncaProxy';
import type { OverviewOptions } from '../types';

export const useUpdateMessage = (data: {
    overviewOptions: OverviewOptions;
}) => {
    const { overviewOptions } = data;
    const incaForm = useIncaForm();
    const globalInca = useGlobalInca();

    useEffect(() => {
        globalInca.events.onValidate = (opts) => {
            const incaRow = opts.data;

            if (overviewOptions.messageId) {
                const msgRow = incaRow.subTables.Meddelande.find(msg => msg.id === overviewOptions.messageId);

                if (msgRow && !msgRow.subTables.Meddelandeåtgärd.length && confirm('Denna registrering öppnades ifrån ett meddelande i inkorgen. Vill du markera meddelandet som hanterat?')) {
                    const newRow = dataUtils.createDataRow('Meddelandeåtgärd', incaForm.form.metadata);
                    newRow.regvars.Ma_tidsstampel.value = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    msgRow.subTables.Meddelandeåtgärd.push(newRow);
                }
            }

            return true;
        };
    }, [overviewOptions, globalInca, incaForm]);
};
