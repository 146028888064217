import type { CurrentUser, Patient, RegisterRecord } from '@cancercentrum/inca';
import { apiRequest } from '@cancercentrum/inca';
import { AjaxErrorAlert, Personuppgifter, usePromise } from '@cancercentrum/rcc-react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import type { IPOConfig } from '../../types';
import { createNewOverview } from './utils';
import { NavigateToExistingRecord } from './NavigateToExistingRecord';

export const CreateNewOverview = (props: {
    currentUser: CurrentUser;
    patient: Patient;
    diagnos: IPOConfig;
    onCancel?: () => void;
    className?: string;
    useCreateCheckboxToggle?: boolean;
}): ReactElement => {
    const {
        currentUser,
        patient,
        diagnos,
        onCancel,
        className,
        useCreateCheckboxToggle,
    } = props;
    const p = usePromise<RegisterRecord>();
    const [confirmed, setConfirmed] = useState(false);
    const onSubmit = () => {
        p.exec(async () => {
            const { hasActiveChoice } = await apiRequest<{ hasActiveChoice: boolean }>(`/Users/Current/Patients/${patient.id}/ActiveChoice`);
            if (!hasActiveChoice) {
                await apiRequest(`/Users/Current/Patients/${patient.id}/ActiveChoice`, {
                    method: 'PUT',
                    json: { hasActiveChoice: true },
                });
            }

            const res = await createNewOverview(patient.id, diagnos.id, currentUser, 'Ny översikt skapad via startsidan');

            return apiRequest<RegisterRecord>(`/RegisterRecords/${res.result.registerRecordId}`);
        });
    };

    const onConfirmChange: ChangeEventHandler<HTMLInputElement> = e => {
        setConfirmed(e.target.checked);
    };

    if (p.result) {
        return (
            <NavigateToExistingRecord
                rrId={p.result.id}
                patient={patient}
                currentUser={currentUser}
                onCancel={onCancel}
                actions={[]}
            />
        );
    }

    return (
        <div className={className}>
            <h3>Skapa ny översikt i IPÖ {diagnos.diagnos}</h3>
            <Personuppgifter patient={patient} />

            <p>Patienten finns inte registrerad. Du kan starta upp en ny översikt genom att klicka på knappen nedan.</p>
            {!useCreateCheckboxToggle && <p>När du startar en ny översikt intygar du att du har en vårdrelation eller en vårdadministrativ relation till patienten.</p>}

            {useCreateCheckboxToggle && (
                <form>
                    <div className="form-group form-check">
                        <input type="checkbox" id="ConfirmCreateNew" className="form-check-input" checked={confirmed} onChange={onConfirmChange} />
                        <label htmlFor="ConfirmCreateNew" className="form-check-label">
                            Jag intygar att jag har en vårdrelation eller en vårdadministrativ relation till patienten.
                        </label>
                    </div>
                </form>
            )}

            <hr />

            <div className="mb-3">
                {!!onCancel && <Button bsStyle="link" onClick={onCancel} disabled={p.isLoading}>Avbryt</Button>}
                <Button
                    bsStyle="primary"
                    onClick={onSubmit}
                    disabled={p.isLoading || (useCreateCheckboxToggle && !confirmed)}
                >
                    {p.isLoading && <FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />}

                    {useCreateCheckboxToggle && <span>Starta ny översikt för <strong>IPÖ {diagnos.diagnos}</strong></span>}
                    {!useCreateCheckboxToggle && <span>Intygar vårdrelation - starta ny översikt</span>}
                </Button>
            </div>

            {p.error && <AjaxErrorAlert error={p.error} />}
        </div>
    );
};
