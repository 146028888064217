import orderBy from 'lodash/orderBy';
import type { CurrentUser } from '@cancercentrum/inca';
import { incaAccessor } from '@cancercentrum/inca';
import { RoleShortnames } from '../constants';
import type { IPOConfig, Report } from '../types';
import { ReportsTab } from './ReportsTab';
import { ReportsPrototypeTab } from './ReportsPrototypeTab';
import { RegistreradePatienterTab } from './RegistreradePatienterTab';
import type { ReportMenuItem } from './ReportsTab/useGetReports';
import { TopLevelReport } from './ReportsTab/TopLevelReport';
import { ReportGroupTab } from './ReportsTab/ReportGroupTab';

interface TabItem {
    id: string;
    prio: number;
    renderTab: () => JSX.Element;
}

export const TabPrio = {
    MyList: 250,
    Reports: 300,
    ReportsPrototype: 400,
};

/**
 * Hämtar alla flikar som ska visas på startsidan baserat på
 *   - gemensamma flikar
 *   - extra flikar skickade via `additionalTabs`
 *   - flikar från det generella rapportregistret.
 */
export const getStartPageTabs = (options: {
    reports: Report[];
    reportsPrototype: Report[];
    topLevelReports: ReportMenuItem[];
    vdDiagnoser: IPOConfig[];
    selectedDiagnos?: IPOConfig;
    currentUser: CurrentUser;
    activeTab: string;
    additionalTabs?: TabItem[];
}) => {
    const {
        selectedDiagnos,
        vdDiagnoser,
        currentUser,
        reports,
        reportsPrototype,
        topLevelReports,
        activeTab,
        additionalTabs = [],
    } = options;
    const isRoleInrapp = currentUser.role.shortName === RoleShortnames.InrappKval;
    const res: TabItem[] = [
        {
            id: 'registered-patients',
            prio: isRoleInrapp ? 200 : 100,
            renderTab() {
                return <RegistreradePatienterTab eventKey="registered-patients" selectedDiagnos={selectedDiagnos} vdDiagnoser={vdDiagnoser} />;
            },
        },
        {
            id: 'reports',
            prio: TabPrio.Reports,
            renderTab() {
                return (
                    <ReportsTab
                        eventKey="reports"
                        selectedDiagnos={selectedDiagnos}
                        vdDiagnoser={vdDiagnoser}
                        currentUser={currentUser}
                        reports={reports}
                    />
                );
            },
        },
        ...(reportsPrototype.length ? [
            {
                id: 'reports_prototype',
                prio: TabPrio.ReportsPrototype,
                renderTab() {
                    return (
                        <ReportsPrototypeTab
                            currentUser={currentUser}
                            eventKey="reports_prototype"
                            vdDiagnoser={vdDiagnoser}
                            selectedDiagnos={selectedDiagnos}
                            reports={reportsPrototype}
                        />
                    );
                },
            },
        ] : []),
        ...additionalTabs,
        ...topLevelReports.map<TabItem>(x => {
            return {
                id: x.title,
                prio: x.prio,
                renderTab() {
                    if (x.reports.length === 1) {
                        return (
                            <TopLevelReport
                                title={x.title}
                                report={x.reports[0]}
                                vdDiagnoser={vdDiagnoser}
                                currentUser={currentUser}
                                selectedDiagnos={selectedDiagnos}
                                isActive={activeTab === x.title}
                            />
                        );
                    }

                    return (
                        <ReportGroupTab
                            header={x.title}
                            eventKey={x.title}
                            reports={x.reports}
                            vdDiagnoser={vdDiagnoser}
                            currentUser={currentUser}
                            selectedDiagnos={selectedDiagnos}
                        />
                    );
                },
            };
        }),
    ];

    return orderBy(res, [x => x.prio], ['asc']);
};

export const isLoginFromExternalSystem = () => {
    return !!(incaAccessor() as any).startPage?.externalData?.patientIdentifier;
};
