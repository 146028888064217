import { createContext, useContext } from 'react';
import type { FormData } from '@cancercentrum/inca';

export interface GlobalIncaType {
    events: {
        onValidate?: (options: { data: FormData }) => boolean;
        onBeforeRecordSave?: (options: { data: FormData }) => void;
    };
    reload: () => void;
    save: () => Promise<boolean>;
}

export const GlobalIncaContext = createContext<GlobalIncaType | undefined>(undefined);

export const useGlobalInca = () => {
    const ctx = useContext(GlobalIncaContext);
    if (!ctx) {
        throw new Error('GlobalIncaContext is not initialized!');
    }

    return ctx;
};
