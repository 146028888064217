import type { ChangeEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import type { CurrentUser, Patient } from '@cancercentrum/inca';
import { apiRequest } from '@cancercentrum/inca';
import { AjaxErrorAlert, Personuppgifter, usePromise } from '@cancercentrum/rcc-react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { assertPatientContext } from '../../utils/patient';
import { addBidragandeAndAuditLogRows } from './utils';
import { NavigateToExistingRecord } from './NavigateToExistingRecord';

export const ContributeToOverview = (props: {
    existingRowId: number;
    existingRegisterRecordId: number;
    patient: Patient;
    currentUser: CurrentUser;
    className?: string;
    onCancel?: () => void;
}): ReactElement => {
    const {
        existingRowId,
        existingRegisterRecordId,
        patient,
        currentUser,
        className,
        onCancel,
    } = props;
    const [confirmed, setConfirmed] = useState(false);
    const [comment, setComment] = useState('');
    const p = usePromise();
    const isContributeDisabled = !confirmed || p.isLoading;

    const onSubmit = () => {
        p.exec(async () => {
            assertPatientContext(currentUser, patient);

            const { hasActiveChoice } = await apiRequest<{ hasActiveChoice: boolean }>(`/Users/Current/Patients/${patient.id}/ActiveChoice`);
            if (!hasActiveChoice) {
                await apiRequest(`/Users/Current/Patients/${patient.id}/ActiveChoice`, {
                    method: 'PUT',
                    json: { hasActiveChoice: true },
                });
            }

            // Se till att något returneras, bara så att result inte blir null.
            return existingRegisterRecordId;
        });
    };

    const onConfirmChange: ChangeEventHandler<HTMLInputElement> = e => {
        setConfirmed(e.target.checked);
    };

    const onCommentChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
        setComment(e.target.value);
    };

    if (p.result) {
        return (
            <div className={className}>
                <NavigateToExistingRecord
                    rrId={existingRegisterRecordId}
                    patient={patient}
                    currentUser={currentUser}
                    onCancel={onCancel}
                    actions={[
                        () => addBidragandeAndAuditLogRows(patient.id, existingRowId, `Användarkommentar: ${comment}`, currentUser),
                    ]}
                />
            </div>
        );
    }

    return (
        <div className={className}>
            <h3>Bli bidragande till patientens vård</h3>
            <Personuppgifter patient={patient} />

            <form>
                <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="ConfirmContribute" checked={confirmed} onChange={onConfirmChange} />
                    <label htmlFor="ConfirmContribute" className="form-check-label">
                        <div>Härmed intygar jag, {currentUser.user.firstName} {currentUser.user.lastName}, att jag har en vårdrelation eller administrativ vårdrelation till patienten.</div>
                        <div>Patienten är informerad och samtycker till att jag tar del av information från tidigare vårdgivare.</div>
                        <div>Om patienten remitteras mellan olika kliniker på samma sjukhus eller mellan olika offentliga sjukhus i samma landsting krävs inget samtycke.</div>
                    </label>
                </div>

                <div className="form-group">
                    <label htmlFor="Comment" className="form-label">Övrig kommentar</label>
                    <textarea id="Comment" value={comment} onChange={onCommentChange} className="form-control" />
                </div>
            </form>

            <div className="mt-3">
                {!!onCancel && <Button bsStyle="link" onClick={onCancel} disabled={p.isLoading}>Avbryt</Button>}
                <Button
                    bsStyle="primary"
                    onClick={onSubmit}
                    disabled={isContributeDisabled}
                >
                    {p.isLoading && <FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />}
                    Bli bidragande i patientens vård
                </Button>
            </div>

            {p.error && <AjaxErrorAlert error={p.error} className="mt-3" />}
        </div>
    );
};
