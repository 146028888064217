import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { incaAccessor } from '@cancercentrum/inca';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const NewPatientOverview = () => {
    useEffect(() => {
        const inca = incaAccessor();
        const handler = () => {
            window.alert('En ny patientöversikt kan inte skapas via Sök/registrera utan endast från startsidan.');

            return false;
        };

        inca.on('validation', handler);

        return () => {
            inca.off('validation', handler);
        };
    }, []);

    return (
        <div>
            <h1><FontAwesomeIcon icon={faInfoCircle} /> Skapa ny patientöversikt</h1>
            <p>En ny patientöversikt kan inte skapas med ärende via Sök/registera utan endast från startsidan.</p>
        </div>
    );
};
