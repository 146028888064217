import memoize from 'lodash/memoize';
import type { CurrentUser, DesignedFormTableMetadata } from '@cancercentrum/inca';
import type { RowModel } from '@cancercentrum/rcc-react';
import { getCurrentIncaDate } from './date';

const SkapadPosRegex = /skapadPos_vd$/;
const SkapadRollRegex = /skapadRoll_vd$/;
const SkapadDatumRegex = /skapadDatum$/;

const findSkapadPosShortname = memoize((tableMetadata: DesignedFormTableMetadata) => {
    return Object.keys(tableMetadata.regvars).find(x => SkapadPosRegex.test(x)) || null;
});

const findSkapadRollShortname = memoize((tableMetadata: DesignedFormTableMetadata) => {
    return Object.keys(tableMetadata.regvars).find(x => SkapadRollRegex.test(x)) || null;
});

const findSkapadDatumShortname = memoize((tableMetadata: DesignedFormTableMetadata) => {
    return Object.keys(tableMetadata.regvars).find(x => SkapadDatumRegex.test(x)) || null;
});

export const createRowHook = (newRow: RowModel, currentUser: CurrentUser) => {
    const { tableMetadata } = newRow.$rcc;
    const skapadPosShortname = findSkapadPosShortname(tableMetadata);
    const skapadRollShortname = findSkapadRollShortname(tableMetadata);
    const skapadDatumShortname = findSkapadDatumShortname(tableMetadata);
    let result = newRow;

    if (skapadPosShortname) {
        result = result.setRegvarValue(skapadPosShortname, currentUser.position.id);
    }
    if (skapadRollShortname) {
        result = result.setRegvarValue(skapadRollShortname, currentUser.role.id);
    }
    if (skapadDatumShortname) {
        result = result.setRegvarValue(skapadDatumShortname, getCurrentIncaDate());
    }

    return result;
};
