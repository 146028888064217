export const RegisterIds = {
    IncaOversikt: 2622,
};

export const RoleShortnames = {
    InrappKval: 'Inrapp (Kval)',
    InrappLakemedel: 'inrapp (lkm)',
};

// Based on list item identifier.
export const SammantagenBedomningColors: Record<string, string> = {
    r1: '#aaa',
    cr: '#86cd86',
    skr: '#86cd86',
    pr: '#86cd86',
    rf: '#86cd86',
    vgpr: '#86cd86',
    sd: '#aaa',
    pd: '#f00',
    t1: '#f00',
    ö: '#f00',
    m0: '#d3d3d3',
    rd: '#ff0000',
    m1: '#ff0000', // Samma färg men olika benämning i sammantagen bedömning vs listan per lokal i bilddia
    m: '#ff0000',
    fm: '#ff0000',
    ny: '#ff0000',
    n: '#ff4c00',
    r: '#86cd86',
    of: '#ddd',
    ab: '#ddd',
    ek: '#ddd',
    eb: '#ddd',
    km: '#f00',
};

export const SammantagenBedomningDescriptions: Record<string, string> = {
    cr: 'Inga synliga metastaser/tumör.',
    sd: 'Väsentligen oförändrad.',
    pd: 'Signifikant progress.',
};

export const ECOGColors: Record<string, string> = {
    0: '#d3d3d3',
    1: '#d3d3d3',
    2: '#ffe500',
    3: '#ffb500',
    4: '#ff7500',
};
