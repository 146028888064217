import { DesignedForm } from '@cancercentrum/rcc-react';
import type { FormData } from '@cancercentrum/inca';
import { incaAccessor } from '@cancercentrum/inca';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { GlobalIncaType } from '../components/GlobalIncaProxy';
import { GlobalIncaContext } from '../components/GlobalIncaProxy';
import { validationPredicate } from './utils/row';

const CONFIRM_TEXT = 'Det finns osparade ändringar i formuläret. Är du säker på att du vill lämna sidan?';

export const FormGlobalIncaProxy = (props: {
    children: JSX.Element;
}) => {
    const { children } = props;
    const isFinished = useRef(false);
    const [isObliterated, setIsObliterated] = useState(false);
    const onRecordSave = useCallback(() => (isFinished.current = true), [isFinished]);
    const onBeforeFormUnload = useCallback((eData: any) => {
        if (!isFinished.current && (eData && eData.history.length)) {
            return confirm(CONFIRM_TEXT);
        }
        return true;
    }, []);
    const onBeforeWindowUnload = useCallback((e: Event, eData: any) => {
        if (!isFinished.current && (eData && eData.history.length)) {
            const message = CONFIRM_TEXT;
            // @ts-ignore todo: fixa
            e.returnValue = message;
            return message;
        }
    }, []);
    const globalInca = useMemo<GlobalIncaType>(() => {
        return {
            events: {},
            reload: () => {
                (window.top || window).location.reload();
            },
            save: () => {
                (incaAccessor().form as any).record.save();

                // inca.form.record.save doesn't return anything, so we don't really know if the save was successful, ongoing, aborted due to
                // validation errors or anything else. INCA automatically reloads after a save, so assume it takes no more than 10s, and if it does
                // assume it couldn't save, for example due to validation errors.
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(false);
                    }, 10000);
                });
            },
        };
    }, []);

    const onValidation = (incaData: FormData) => {
        const incaRow = incaData || incaAccessor().form.data;

        return globalInca.events.onValidate?.({ data: incaRow });
    };

    const onBeforeRecordSave = (incaData: FormData) => {
        const incaRow = incaData || incaAccessor().form.data;

        globalInca.events.onBeforeRecordSave?.({ data: incaRow });
    };

    const onObliterated = () => {
        setIsObliterated(true);
    };

    if (isObliterated) {
        return null;
    }

    return (
        <GlobalIncaContext.Provider value={globalInca}>
            <DesignedForm
                onBeforeFormUnload={onBeforeFormUnload}
                onBeforeWindowUnload={onBeforeWindowUnload}
                onRecordSave={onRecordSave}
                onObliteration={onObliterated}
                onValidation={onValidation}
                validationPredicate={validationPredicate}
                onBeforeRecordSave={onBeforeRecordSave}
            >
                {() => children}
            </DesignedForm>
        </GlobalIncaContext.Provider>
    );
};
