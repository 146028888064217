import { differenceInYears, format, formatISO, isAfter, isBefore, isSameDay, isValid, parseISO } from 'date-fns';

export const getCurrentIncaDate = (): string => format(new Date(), 'yyyy-MM-dd');

export const isValidDate = (input: string | null | undefined): input is string => {
    if (!input) {
        return false;
    }

    return isValid(parseISO(input));
};

/**
 * Beräkna ålder baserata på födelse- och dödsdatum. Om dödsdatum saknas beräknas ålder baserat på dagens datum.
 */
export const calculateAge = (dateOfBirth: string, dateOfDeath: string | null | undefined): number | null => {
    const birth = parseISO(dateOfBirth);
    const end = dateOfDeath ? parseISO(dateOfDeath) : new Date();

    if (!isValid(birth) || !isValid(end)) {
        return null;
    }

    return differenceInYears(end, birth);
};

/**
 * Är första datumet samma dag eller efter andra datumet.
 */
export const isSameDayOrAfter = (dateLeft: Date, dateRight: Date) => {
    return isSameDay(dateLeft, dateRight) || isAfter(dateLeft, dateRight);
};

/**
 * Är första datumet samma dag eller före andra datumet.
 */
export const isSameDayOrBefore = (dateLeft: Date, dateRight: Date) => {
    return isSameDay(dateLeft, dateRight) || isBefore(dateLeft, dateRight);
};

/**
 * Kontrollerar om datumet är mellan två datum, inklusive extremerna.
 */
export const isBetweenDaysInclusive = (date: Date, start: Date, end: Date) => {
    return isSameDayOrAfter(date, start) && isSameDayOrBefore(date, end);
};

export const formatIncaDate = (date: Date) => {
    return formatISO(date, { representation: 'date' });
};

export const getUTCStartOfDayTime = (date: Date) => {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
};

export const isWithinLastYear = (date: Date) => {
    return differenceInYears(new Date(), date) === 0;
};

export const isStrDateWithinLastYear = (strDate: string | null) => {
    return !!strDate && isValidDate(strDate) && isWithinLastYear(parseISO(strDate));
};
