import type { ChangeEventHandler, FormEventHandler, Ref } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';

export const SearchBox = (props: Omit<JSX.IntrinsicElements['div'], 'onChange' | 'value'> & {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    isSearching?: boolean;
    onSearch: () => void;
    inputRef: Ref<HTMLInputElement>;
}) => {
    const { value, onChange, isSearching, onSearch, inputRef, ...rest } = props;
    let icon = <FontAwesomeIcon icon={faSearch} className="me-2" />;

    if (isSearching) {
        icon = <FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />;
    }

    const search: FormEventHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        onSearch();
    };

    return (
        <div {...rest}>
            <form onSubmit={search}>
                <label className="form-label">Ange personnummer för patienten</label>
                <div className="input-group" style={{ width: '250px' }}>
                   <input type="text" className="form-control" value={value} onChange={onChange} ref={inputRef} />
                   <div className="input-group-btn">
                       <Button bsStyle="primary" type="submit" disabled={!value || isSearching}>
                            {icon} Sök
                        </Button>
                   </div>
                </div>
            </form>
        </div>
    );
};
