import { hierarchifyVDResult } from '@cancercentrum/inca';
import type { DiagnoskonfigVDItem, HierarchifiedDiagnoskonfig } from '../form-oversikt/types';
import type { IPOConfig } from '../types';

export const normalizeConfigs = (allLayouts: DiagnoskonfigVDItem[]): IPOConfig[] => {
    const hierarchified: HierarchifiedDiagnoskonfig[] = hierarchifyVDResult(allLayouts, [
        {
            idCol: 'UTFORMNING_ID',
            tableName: 'Utformning',
            subTables: [
                {
                    idCol: 'LAN_ID',
                    tableName: 'Lan',
                    columns: ['lan_fk'],
                },
                {
                    idCol: 'REGION_ID',
                    tableName: 'Region',
                    columns: ['region_fk'],
                },
                {
                    idCol: 'REGISTER_ID',
                    tableName: 'Register',
                    columns: ['register_fk'],
                },
                {
                    idCol: 'ROLL_ID',
                    tableName: 'Roll',
                    columns: ['roll_fk'],
                },
            ],
            columns: ['namn', 'konfignamn', 'settings'],
        },
        {
            idCol: 'ICD10KOD_ID',
            tableName: 'ICD10Kod',
            columns: ['icd10kod_vd', 'icd10kod_vd_kod'],
        },
        {
            idCol: 'ICD10KAT_ID',
            tableName: 'ICD10Kategori',
            columns: ['icd10kat_vd', 'icd10kat_vd_kategorikod'],
        },
    ]);

    return hierarchified.map<IPOConfig>(x => {
        const utf = x.subTables.Utformning[0]; // There is always only exactly one that matched the VD parameters.

        const conf: IPOConfig = {
            id: x.id,
            diagnos: x.data.diagnos,
            diagnosgrupp: x.data.diagnosgrupp,
            diagnos_varde: x.data.diagnos_Värde,
            diagnos_beskrivning: x.data.diagnos_Beskrivning,
            layout: {
                id: utf.id,
                configName: utf.data.konfignamn,
                settings: {
                    ...JSON.parse(x.data.rotsettings || '{}'),
                    ...JSON.parse(utf.data.settings || '{}'),
                },
                name: utf.data.namn,
            },
        };

        if (x.subTables.ICD10Kod.length) {
            conf.icd10Codes = x.subTables.ICD10Kod.map(y => y.data.icd10kod_vd_kod);
        }

        if (x.subTables.ICD10Kategori.length) {
            conf.icd10CategoryCodes = x.subTables.ICD10Kategori.map(y => y.data.icd10kat_vd_kategorikod);
        }

        return conf;
    });
};
