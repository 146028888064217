import { useEffect } from 'react';
import { dataUtils, useIncaForm } from '@cancercentrum/rcc-react';
import { format } from 'date-fns';
import { useGlobalInca } from '../components/GlobalIncaProxy';

export const useSaveAuditLog = () => {
    const incaForm = useIncaForm();
    const globalInca = useGlobalInca();

    useEffect(() => {
        globalInca.events.onBeforeRecordSave = (opts) => {
            const incaRow = opts.data;

            const logEntry = dataUtils.createDataRow('AuditLog', incaForm.form.metadata);

            logEntry.regvars.al_klientdatum.value = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
            logEntry.regvars.al_kommentar.value = 'Spara i IPÖ-registerpost.';
            logEntry.regvars.al_plattform.value = navigator.platform;
            logEntry.regvars.al_useragent.value = navigator.userAgent;
            logEntry.regvars.al_position_vd.value = incaForm.currentUser.position.id;
            logEntry.regvars.al_roll_vd.value = incaForm.currentUser.role.id;
            logEntry.regvars.al_anvandarnamn.value = incaForm.currentUser.user.username;

            incaRow.subTables.AuditLog.push(logEntry);
        };

    }, [globalInca, incaForm]);
};
