// Utils when rendering an overview

import type { CurrentUser, FormData, Patient, Register } from '@cancercentrum/inca';
import { apiRequest, incaAccessor } from '@cancercentrum/inca';
import type { CreateStoreCallback, CreateValidatorCallback } from '@cancercentrum/rcc-react';
import { createValidator as defaultCreateValidator, dataReducer, dataUtils, detachedRowReducer, gridReducer, registerReducer, settingsReducer, tabManagerReducer, validationReducer } from '@cancercentrum/rcc-react';
import minBy from 'lodash/minBy';
import type { ReactElement } from 'react';
import uniq from 'lodash/uniq';
import { configureStore } from '@reduxjs/toolkit';
import type { ApiRegister, DataSet, DiagnoskonfigVDData, FormConfigProps, OrganisationsenhetVDData, OrganisationsenhetVDItem } from '../types';
import type { GetValueDomainValues, IPOConfig } from '../../types';
import { normalizeConfigs } from '../../utils/configUtils';
import minidataset from '../configs/minidataset';
import loadFormConfig from './loadFormConfig';

const SYSTEM_TABLES: (keyof DataSet)[] = ['Oversikt', 'Bidragande', 'Meddelande', 'Meddelandeåtgärd'];

export const createOverviewStore: CreateStoreCallback = (data) => {
    const { inca } = data;
    const rootTableName = minBy(Object.keys(inca.form.metadata), x => inca.form.metadata[x].treeLevel);
    const initialTabKey = (window as any).inca?.userDefined?.initialTab || null;
    const dataState = dataUtils.createDataState(inca.form.data, inca.form.metadata, rootTableName);

    return configureStore({
        reducer: {
            tabManager: tabManagerReducer,
            data: dataReducer,
            settings: settingsReducer,
            grid: gridReducer,

            // @ts-ignore detachedRow ej konverterad till slice än
            detachedRow: detachedRowReducer,

            register: registerReducer,
            validation: validationReducer,
        },
        preloadedState: { data: dataState, tabManager: { activeTab: initialTabKey } },
        middleware: getDefaultMiddleware => {
            return getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            });
        },
    });
};

export const createOverviewValidator: CreateValidatorCallback = (data) => {
    const { inca } = data;
    const val = defaultCreateValidator(inca.patient);

    val.addMethod('minLimit', function (value, param) {
        return this.optional(value) || value >= param;
    }, 'validation.minLimit');

    val.addMethod('maxLimit', function (value, param) {
        return this.optional(value) || value <= param;
    }, 'validation.maxLimit');

    return val;
};

export const getSamtycke = (opts: {
    getValueDomainValues: GetValueDomainValues;
    patient: Patient;
}): Promise<boolean> => {
    const { patient } = opts;

    return apiRequest<{ aggregatedPatientRecord: boolean; }>(`/Patients/${patient.id}/AggregatedPatientRecord`).then(res => {
        if (process.env.NODE_ENV === 'development') {
            const query = new URLSearchParams(location.search);

            if (query.get('samtycke')) {
                return query.get('samtycke') === 'true';
            }
        }

        return res.aggregatedPatientRecord;
    });
};

/**
 * Gör en patientsökning så det sparas i INCA:s aktivitetslogg.
 */
export const addIncaActivityLog = (ssn: string, diagnos: string) => {
    return apiRequest('/Patients/Find', {
        method: 'POST',
        json: {
            ssn: ssn,
            allowSearchInExternalPopulationRegister: false,
            information: `IPÖ - ${diagnos}`,
        },
    });
};

export const getContributingPositions = (opts: {
    getValueDomainValues: GetValueDomainValues;
    currentUser: CurrentUser;
    initialData: FormData;
}): Promise<OrganisationsenhetVDItem[]> => {
    const { getValueDomainValues, currentUser, initialData } = opts;
    const getUniquePositionIds = () => {
        const idSet = new Set([currentUser.position.id]);

        const addIdsFromRow = (rowData: FormData) => {
            if (rowData.dataRowOwnerPositionId) {
                idSet.add(rowData.dataRowOwnerPositionId);
            } else {
                // eslint-disable-next-line no-console
                console.warn('dataRowOwnerPositionId is missing!', rowData);
            }

            Object.keys(rowData.subTables).forEach(subTableName => {
                rowData.subTables[subTableName].forEach(r => {
                    addIdsFromRow(r);
                });
            });
        };

        addIdsFromRow(initialData);

        return Array.from(idSet);
    };

    const uniquePositionIds2 = getUniquePositionIds();

    return getValueDomainValues<OrganisationsenhetVDData>('VD_Organisationsenheter', {
        parameters: {
            posIds: [-1, ...uniquePositionIds2],
        },
    });
};

export const getIpoLayoutInfo = async (opts: {
    getValueDomainValues: GetValueDomainValues;
    currentUser: CurrentUser;
    configId: number;
}): Promise<{
    config: IPOConfig;
    dataset: DataSet;
    fullDataset: DataSet;
    registersWithReportAccess: Register[];
    configComponent: (props: FormConfigProps) => ReactElement;
}> => {
    const { getValueDomainValues, currentUser, configId } = opts;

    const [registers, readonlyRegisters] = await Promise.all([
        apiRequest<ApiRegister[]>('/Registers', {
            params: { accessType: 'registration' },
        }),
        apiRequest<ApiRegister[]>('/Registers', {
            params: { accessType: 'readonly' },
        }),
    ]);
    const regIds = uniq([
        ...registers.map(x => x.id),
        ...readonlyRegisters.map(x => x.id),
    ]);
    const cfgs = await getValueDomainValues<DiagnoskonfigVDData>('diagnoskonfiguration', {
        parameters: {
            configId: [configId || -1],
            roleId: [currentUser.role.id || -1],
            regionId: [currentUser.region.id || -1],
            countyId: [currentUser.position.countyId || -1],
            registerIds: [-1, ...regIds],
        },
    });

    // Det kan bli produkter om en användare har behörighet till flera kvalitetsregister.
    const availableConfigs = normalizeConfigs(cfgs).filter(x => !x.layout.settings.blacklistedUsernames?.includes(currentUser.user.username));

    if (!availableConfigs.length) {
        throw new Error('Ogiltig registerpost eller så saknas behörighet.');
    }

    const normalizedConfig = availableConfigs[0];

    let configName = normalizedConfig.layout.configName;

    if (process.env.NODE_ENV === 'development') {
        const query = new URLSearchParams(location.search);

        configName = query.get('configName') || 'diagnosis_brostcancer';
        const configNamesToDiagnosisName: Record<string, string> = {
            diagnosis_ppc: 'PPC',
        };

        normalizedConfig.diagnos = configNamesToDiagnosisName[configName] || normalizedConfig.diagnos;
    }

    try {
        const res = await loadFormConfig(configName);
        if (!res.dataset) {
            throw new Error(`The dataset definition is missing from the configuration for "${configName}".`);
        }

        const tableNames = [...SYSTEM_TABLES, ...Object.keys(res.dataset)] as (keyof DataSet)[];

        const fullDataset = tableNames.reduce<DataSet>((reduction, tableName) => {
            reduction[tableName] = {
                ...minidataset[tableName],
                ...res.dataset[tableName],
            };

            return reduction;
        }, {});

        return {
            config: normalizedConfig,
            dataset: res.dataset,
            fullDataset,
            configComponent: res.default,
            registersWithReportAccess: registers,
        };
    } catch (err) {
        (incaAccessor().services as any).logging?.logError?.(err);

        throw new Error('Felaktig konfiguration, var god kontakta en administratör.');
    }
};
