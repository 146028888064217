const configs: {
    id: string;
    title: string;
}[] = [
    {
        title: 'Bröstcancer',
        id: 'diagnosis_brostcancer',
    },
    {
        title: 'CNS',
        id: 'diagnosis_hjarntumorer',
    },
    {
        title: 'Hudmelanom',
        id: 'diagnosis_hudmelanom',
    },
    {
        title: 'Lung- eller lungsäckscancer',
        id: 'diagnosis_lungcancer',
    },
    {
        title: 'Myelom',
        id: 'diagnosis_myelom',
    },
    {
        title: 'Njurcancer',
        id: 'diagnosis_njurcancer',
    },
    {
        title: 'Prostatacancer',
        id: 'diagnosis_ppctidig',
    },
    {
        title: 'Äggstockscancer',
        id: 'diagnosis_ovarial',
    },
];

if (process.env.RCC_ENV !== 'external_demo') {
    configs.push(...[
        {
            title: 'Gyncancer',
            id: 'diagnosis_gyn',
        },
        {
            title: 'Kronisk lymfatisk leukemi (KLL)',
            id: 'diagnosis_kll',
        },
        {
            title: 'Rollen "Inrapportör (läkemedel)"',
            id: 'role_lakemedel',
        },
    ]);
}

export default configs;
