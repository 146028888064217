import { MenuItem, Nav, NavDropdown } from '@cancercentrum/rcc-bootstrap';
import classnames from 'classnames';
import type { ReactElement } from 'react';
import availableConfigs from '../availableConfigs';

const defaultConfig = availableConfigs[0];

export const DevelopmentConfigurationSelector = (): ReactElement | null => {
    const isDev = !process.env.RCC_SOURCE_FOLDER;
    const query = new URLSearchParams(location.search);
    const selectedConfigId = query.get('configName');
    const selectedConfig = availableConfigs.find(x => x.id === selectedConfigId) || defaultConfig;

    if (!isDev) {
        return null;
    }

    return (
        <nav className="navbar navbar-expand-lg dev-navigator navbar-dark bg-primary">
            <div className="container justify-content-start">
                <a href="src/form-oversikt" className="navbar-brand me-3"> Individuell patientöversikt</a>
                <Nav className="navbar-nav">
                    <NavDropdown title={selectedConfig.title} id="basic-nav-dropdown" className="dev-dia-dropdown">
                        {availableConfigs.map((c, i) => {
                            return (
                                <MenuItem key={i} href={`?configName=${c.id}`} className={classnames({ active: selectedConfig === c })}>
                                    {c.title}
                                </MenuItem>
                            );
                        })}
                    </NavDropdown>
                </Nav>
            </div>
        </nav>
    );
};
