import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { Patient } from '@cancercentrum/inca';
import { incaAccessor } from '@cancercentrum/inca';
import { LoadingAlert } from '@cancercentrum/rcc-react';
import { addBidragandeAndAuditLogRows } from '../../components/SearchAndNavigateToOverview/utils';
import type { OversiktRowModel } from '../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useGlobalInca } from '../../components/GlobalIncaProxy';
import useCurrentUser from '../hooks/useCurrentUser';

export interface ContributingProps {
    row: OversiktRowModel;
    children: ReactElement;
    patient: Patient;
}

const navigateToRegisterRecord = (options: {
    reload: () => void;
}) => {
    const inca = incaAccessor();
    if (inca?.overview?.supportsTabs) {
        inca.overview.tabs.add((tabId: number) => {
            // Se till att eventuell inca.userDefined behålls. Det behövs för att fliken ska stängas korrekt vid uthopp från kvalitetsregister då
            // kliniken inte redan är bidragande.
            inca.overview.tabs.setContent(tabId, 'rr', inca.form.data.registerRecordId!, 'Individuell patientöversikt (registerpost)', inca.userDefined);
            inca.overview.tabs.remove(inca.overview.tabs.getCurrentTabId());
            inca.overview.tabs.activate(tabId);
        });
    } else {
        options.reload();
    }
};

const useEnsureBidragande = (row: OversiktRowModel, patient: Patient): boolean => {
    const currentUser = useCurrentUser();
    const gi = useGlobalInca();
    const isBidragande = row.getSubTableRows('Bidragande').some(x => x!.getRegvarValue('bidr_position_fk') === currentUser.position.id);

    useEffect(() => {
        if (!isBidragande) {
            addBidragandeAndAuditLogRows(patient.id, row.getId() as number, 'Automatiskt bidragande då användaren öppnat en behörig post.', currentUser).then(() => {
                navigateToRegisterRecord({ reload: gi.reload });
            });
        }
    }, [isBidragande, patient.id, row, currentUser, gi]);

    return isBidragande;
};

/**
 * Ensures the current position has been added to the 'Bidragande' sub table.
 */
export const Contributing = (props: ContributingProps): ReactElement => {
    const { row, children, patient } = props;
    const isBidragande = useEnsureBidragande(row, patient);

    if (!isBidragande) {
        return <LoadingAlert />;
    }

    return children;
};
