import { useState } from 'react';
import type { CurrentUser } from '@cancercentrum/inca';
import { Alert, Tab, Tabs } from '@cancercentrum/rcc-bootstrap';
import { atom, useAtom } from 'jotai';
import { Activatable } from '../../components/Activatable';
import type { IPOConfig, Report } from '../../types';
import { StartPageShinyReport } from '../StartPageShinyReport';
import { ReportTitle } from './ReportTitle';

const activeKeyAtom = atom<string | null>(null);

export const ReportsTabContent = (props: {
    vdDiagnoser: IPOConfig[];
    tabId: string;
    currentUser: CurrentUser;
    selectedDiagnos?: IPOConfig;
    reports: Report[];
}): JSX.Element | null => {
    const { vdDiagnoser, currentUser, selectedDiagnos, tabId, reports } = props;
    const [activeKey, setActiveKey] = useAtom(activeKeyAtom);
    const [loadingReports, setLoadingReports] = useState(new Set<string>());

    const onSelect = (eventKey: any): void => {
        setActiveKey(eventKey);
    };

    const onReportLoading = (shortname: string) => {
        setLoadingReports(x => {
            const newSet = new Set(x);
            newSet.add(shortname);

            return newSet;
        });
    };

    const onReportLoaded = (shortname: string) => {
        setLoadingReports(x => {
            const newSet = new Set(x);
            newSet.delete(shortname);

            return newSet;
        });
    };

    return (
        <div className="mt-2">
            {!reports.length && (
                <Alert bsStyle="info">
                    Du saknar behörighet till några rapporter.
                </Alert>
            )}

            {!!reports.length && (
                <Tabs id={tabId} bsStyle="pills" activeKey={activeKey} onSelect={onSelect} animation={false}>
                    {reports.map(x => {
                        const eventKey = `${x.data.shiny_kortnamn}_${x.id}`;

                        return (
                            <Tab key={x.id} eventKey={eventKey} title={<ReportTitle title={x.data.titel} isLoading={loadingReports.has(eventKey)} />}>
                                <div className="mt-2">
                                    <Activatable isActive={activeKey === eventKey}>
                                        <StartPageShinyReport
                                            selectedDiagnos={selectedDiagnos}
                                            vdDiagnoser={vdDiagnoser}
                                            currentUser={currentUser}
                                            report={x}
                                            onLoading={() => onReportLoading(eventKey)}
                                            onLoaded={() => onReportLoaded(eventKey)}
                                        />
                                    </Activatable>
                                </div>
                            </Tab>
                        );
                    })}
                </Tabs>
            )}
        </div>
    );
};
