import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { atom } from 'jotai';
import type { IPOConfig } from '../types';

export const selectedStartPageTabAtom = atom('');

export const availableLayoutsAtom = atom<IPOConfig[]>([]);

export const selectedDiagnosIdAtom = atomWithStorage<number | null>('ipo/startpage/selected_diagnos_id',null, createJSONStorage(() => window.sessionStorage));

export const selectedDiagnosAtom = atom(get => {
    const layouts = get(availableLayoutsAtom);
    const id = get(selectedDiagnosIdAtom);

    return layouts.find(x => x.id === id);
});

export const renderOptionsAtom = atom({
    loginFromExternalSystem: false,
});
