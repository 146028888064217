import { useCallback, useContext } from 'react';
import type { RenderTabOptions } from '../../types';
import HTabContext from './HTabsContext';

export const useHTabs = (tabId: string) => {
    const ctx = useContext(HTabContext);
    if (!ctx) {
        throw new Error('HTabsContext must be initialized.');
    }

    const renderTab = useCallback((options: RenderTabOptions) => {
        ctx.renderTab(tabId, options);
    }, [ctx, tabId]);
    const removeTab = useCallback(() => {
        ctx.removeTab(tabId);
    }, [ctx, tabId]);

    return { renderTab, removeTab };
};
