import get from 'lodash/get';
import merge from 'lodash/merge';
import type { DesignedFormTableMetadata, FormData } from '@cancercentrum/inca';
import { incaGenerator } from '@cancercentrum/inca-dev';
import minidataset from '../form-oversikt/configs/minidataset';
import diagnosis_brostcancer from '../form-oversikt/configs/diagnosis_brostcancer/dataset';
import diagnosis_gyn from '../form-oversikt/configs/diagnosis_gyn/dataset';
import diagnosis_hjarntumorer from '../form-oversikt/configs/diagnosis_hjarntumorer/dataset';
import diagnosis_hudmelanom from '../form-oversikt/configs/diagnosis_hudmelanom/dataset';
import diagnosis_kll from '../form-oversikt/configs/diagnosis_kll/dataset';
import diagnosis_lungcancer from '../form-oversikt/configs/diagnosis_lungcancer/dataset';
import diagnosis_njurcancer from '../form-oversikt/configs/diagnosis_njurcancer/dataset';
import diagnosis_ppctidig from '../form-oversikt/configs/diagnosis_ppctidig/dataset';
import diagnosis_myelom from '../form-oversikt/configs/diagnosis_myelom/dataset';
import diagnosis_ovarial from '../form-oversikt/configs/diagnosis_ovarial/dataset';
import role_lakemedel from '../form-oversikt/configs/role_lakemedel/dataset';
import errand from './muu2.0/form-oversikt/brostcancer/form.brostcancer.muu.errand.json' assert { type: 'json' };
import brost from './muu2.0/form-oversikt/brostcancer/form.brostcancer.muu.rr.json' assert { type: 'json' };
import gyn from './muu2.0/form-oversikt/gyn/form.gyn.muu.rr.json' assert { type: 'json' };
import cns from './muu2.0/form-oversikt/hjarntumorer/form.hjarntumorer.muu.rr.json' assert { type: 'json' };
import kll from './muu2.0/form-oversikt/kll/form.kll.muu.rr.json' assert { type: 'json' };
import lunga from './muu2.0/form-oversikt/lunga/form.lunga.muu.rr.json' assert { type: 'json' };
import hudmelon from './muu2.0/form-oversikt/hudmelanom/form.hudmelon.muu.rr.json' assert { type: 'json' };
import njure from './muu2.0/form-oversikt/njure/form.njure.muu.rr.json' assert { type: 'json' };
import prostata from './muu2.0/form-oversikt/prostata/form.prostata.muu.rr.json' assert { type: 'json' };
import myelom from './muu2.0/form-oversikt/myelom/form.myelom.muu.rr.json' assert { type: 'json' };
import ovarial from './muu2.0/form-oversikt/ovarial/form.ovarial.muu.rr.json' assert { type: 'json' };
import lkm from './muu2.0/form-oversikt/lakemedel/form.lakemedel.muu.rr.json' assert { type: 'json' };
// @ts-ignore muu
import { IPO_TESTSSON_PATID, IPO_TESTSSON_PNR } from './muu2.0/form-oversikt/constants';

let data;
let role = 9;

const query = new URLSearchParams(location.search);
const configName = query.get('configName') || 'diagnosis_brostcancer';

switch (query.get('configName')) {
    case 'diagnosis_brostcancer':
        data = brost.data;
        break;

    case 'diagnosis_gyn':
        data = gyn.data;
        break;

    case 'diagnosis_hjarntumorer':
        data = (cns as any).data;
        break;

    case 'diagnosis_kll':
        data = kll.data;
        break;

    case 'diagnosis_lungcancer':
        data = lunga.data;
        break;

    case 'diagnosis_hudmelanom':
        data = (hudmelon as any).data;
        break;

    case 'diagnosis_njurcancer':
        data = njure.data;
        break;

    case 'diagnosis_ppctidig':
        data = prostata.data;
        break;

    case 'diagnosis_myelom':
        data = myelom.data;
        break;

    case 'diagnosis_ovarial':
        data = ovarial.data;
        break;

    case 'role_lakemedel':
        data = lkm.data;
        role = 87;
        break;

    default:
        data = brost.data;
        break;
}

const testsson_env_overrides = {
    _PATIENTID: IPO_TESTSSON_PATID.toString(),
    _PERSNR: IPO_TESTSSON_PNR,
};

const envOverrides: Record<string, any> = {
    'diagnosis_brostcancer': testsson_env_overrides,
    'diagnosis_hjarntumorer': testsson_env_overrides,
    'diagnosis_ppctidig': testsson_env_overrides,
    'diagnosis_ovarial': testsson_env_overrides,
    'diagnosis_myelom': testsson_env_overrides,
    'diagnosis_njurcancer': testsson_env_overrides,
    'diagnosis_lungcancer': testsson_env_overrides,
    'diagnosis_hudmelanom': testsson_env_overrides,
};

const user = {
    id: 7909,
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    username: 'oc0ipodemo',
    email: 'noreply@incanet.se',
    phoneNumber: '123456',
    role: {
        id: 9,
        name: 'Inrapportör (Kvalitetsregister)',
        isReviewer: false,
    },
    position: {
        id: 38,
        name: 'Kirurgen',
        code: '301',
        fullNameWithCode: 'OC Demo (0) - SU/Sahlgrenska (500010) - Kirurgen (301)',
    },
    region: {
        id: 7,
        name: 'Region Demo',
    },
    previousLogin: '2019-05-15T13:10:04.013Z',
};

const env = {
    '_INREPNAME': '',
    '_INUNITNAME': '',
    '_REPORTERNAME': '',
    '_ADDRESS': 'Progatan 13',
    '_ADDRESSADDITIONALINFO': '',
    '_ADDRESSCAREOF': '',
    '_AVREGDATUM': '',
    '_AVREGORSAK': '',
    '_BEFOLKNINGSREGISTER': '',
    '_CITY': 'Ankeborg',
    '_DATEOFDEATH': '',
    '_DISTRICT': '',
    '_DODSORSAK': '',
    '_DODSORSAKSBESKR': '',
    '_FIRSTNAME': 'Leif',
    '_FODELSEDATUM': '1967-01-02',
    '_FORSAMLING': '00',
    '_KOMMUN': '37',
    '_LAN': '13',
    '_LAND': 'Sweden',
    '_LKF': '133700',
    '_LIMITEDDATAPROCESSING': false,
    '_NAVETDATUM': null,
    '_PATIENTID': '3184128',
    '_PERSNR': '13371337-1337',
    '_POSTALCODE': '12345',
    '_SECRET': 'False',
    '_SEX': 'M',
    '_SURNAME': 'Leetsson',
    '_SPA_ADDRESSCAREOF': '',
    '_SPA_ADDRESSADDITIONALINFO': '',
    '_SPA_ADDRESS': '',
    '_SPA_POSTALCODE': '',
    '_SPA_CITY': '',
    ...envOverrides[configName],
};

export const incaRegisterRecordMock = incaGenerator.extendWithRegisterRecordInfo({ user, form: { designedFormId: brost.designedFormId, env, metadata: brost.metadata, data: data } });
export const incaErrandMock = incaGenerator.extendWithErrandInfo({ user, form: { env, metadata: errand.data, data: errand.metadata } });

incaRegisterRecordMock.user.role.id = role;

if (process.env.NODE_ENV !== 'test') {
    // Behövs för att få personuppgiftsblocket att visas efter scroll. På INCA är förälderelement redan relativ så för att få liknande beteende i offlineläge behövs denna raden.
    document.getElementById('Inca')!.style.position = 'relative';

    (window as any).inca = query.get('errand') ? incaErrandMock : incaRegisterRecordMock;
    (window as any).inca.form.record = {
        save: () => {
            // eslint-disable-next-line no-console
            console.log('saving');
        },
    };

    if (query.get('ro')) {
        (window as any).inca.form.isReadOnly = true;
    }

    if (query.get('noTabs')) {
        (window as any).inca.overview.supportsTabs = false;
    }

    if (query.get('getUsedRegvars')) {
        const setSpecialare = (row: FormData, suffix: string, value: any) => {
            const regvarShortNames = Object.keys(row.regvars);
            const shortName = regvarShortNames.filter(x => x.endsWith(suffix))[0];
            if (shortName) {
                row.regvars[shortName].value = value;
            }
        };

        const populateSubTables = (row: FormData) => {
            Object.keys(row.subTables).forEach(subTableName => {
                const tableMetadata = (window as any).inca.form.metadata[subTableName];
                const childRow = (window as any).inca.form.createDataRow(subTableName);
                populateSubTables(childRow);

                setSpecialare(childRow, '_skapadPos_vd', 38);
                setSpecialare(childRow, '_skapadDatum', '2018-07-09');

                // Fyll i listvärden. MultiSelectList räknar med att vissa listvärden inte är null.
                Object.keys(tableMetadata.regvars)
                    .filter(shortname => tableMetadata.regvars[shortname].dataType === 'list')
                    .forEach(shortname => {
                        childRow.regvars[shortname].value = tableMetadata.regvars[shortname].listValues[0].id;
                    });

                if (subTableName === 'Läkemedel') {
                    childRow.regvars.Lkm_startdatum.value = '2018-07-09';
                    childRow.regvars.Lkm_substans_vd.value = 190;
                    childRow.regvars.Lkm_behandlingsintention.value = 23846;
                }

                row.subTables[subTableName].push(childRow);
            });
        };

        populateSubTables((window as any).inca.form.data);
    }

    const datasets = [
        diagnosis_brostcancer,
        diagnosis_gyn,
        diagnosis_hjarntumorer,
        diagnosis_hudmelanom,
        diagnosis_kll,
        diagnosis_lungcancer,
        diagnosis_njurcancer,
        diagnosis_ppctidig,
        diagnosis_myelom,
        diagnosis_ovarial,
        role_lakemedel,
    ].map(x => merge({}, minidataset, x));

    const isUsed = (tableName: string, shortname: string) => datasets.some(ds => get(ds, `${tableName}.${shortname}`));

    (window as any).antimetadata = Object.keys(brost.metadata).reduce<Record<string, string[]>>((reduction, tableName) => {
        const shortnames = Object.keys((brost.metadata as Record<string, DesignedFormTableMetadata>)[tableName].regvars);
        const unused = shortnames.filter(shortname => !isUsed(tableName, shortname));
        if (!unused.length) {
            return reduction;
        }
        return {
            ...reduction,
            [tableName]: unused,
        };
    }, {});
}
