import { type ReactElement, useEffect, useRef } from 'react';
import type { CurrentUser, Patient } from '@cancercentrum/inca';
import { apiRequest } from '@cancercentrum/inca';
import { AjaxErrorAlert, useGetValueDomainValues, useInca, usePromise } from '@cancercentrum/rcc-react';
import invariant from 'invariant';
import { unstable_batchedUpdates } from 'react-dom';
import { Alert } from '@cancercentrum/rcc-bootstrap';
import { atom, useAtom } from 'jotai';
import { assertPatientContext } from '../../utils/patient';
import type { GetValueDomainValues, IPOConfig } from '../../types';
import { addIpoEventListener, removeIpoEventListener } from '../../utils/events';
import { SearchBox } from './SearchBox';
import { CreateNewOverview } from './CreateNewOverview';
import { ContributeToOverview } from './ContributeToOverview';
import { NavigateToExistingRecord } from './NavigateToExistingRecord';

interface RegisterRecordMatchVDData {
    ENAMN: string;
    FNAMN: string;
    REGISTERRECORD_ID: number;
    bidr_position_fk: number;
}

interface RRData {
    rrId: number;
    id: number;
    firstname: string;
    surname: string;
    bidragande: number[];
}

export const getPatientStatus = async (ssn: string, configId: number, vdname: string, getValueDomainValues: GetValueDomainValues): Promise<{
    patient: Patient;
    record: RRData;
}> => {
    const [currentUser, patient] = await Promise.all([
        apiRequest<CurrentUser>('/Users/Current'),
        apiRequest<Patient>('/Patients/Find', {
            method: 'POST',
            json: {
                allowSearchInExternalPopulationRegister: true,
                ssn: ssn.replace(/[\s-]/g, ''),
            },
        }),
    ]);

    assertPatientContext(currentUser, patient);

    const opts = {
        parameters: {
            pnr: [patient.ssn],
            diagnoskonfiguration: [configId],
        },
    };

    const res = await getValueDomainValues<RegisterRecordMatchVDData>(vdname, opts);

    const records = res.reduce<RRData[]>((reduction, item) => {
        let match = reduction.find(x => x.id === item.id);
        if (!match) {
            match = {
                rrId: item.data.REGISTERRECORD_ID,
                id: item.id,
                firstname: item.data.FNAMN,
                surname: item.data.ENAMN,
                bidragande: [],
            };

            reduction.push(match);
        }

        match.bidragande.push(item.data.bidr_position_fk);

        return reduction;
    }, []);

    invariant(records.length <= 1, 'Ogiltigt resultat från värdedomänen "registerposter". Förväntat resultat: max en rad');

    return {
        patient,
        record: records[0],
    };
};

const OpenExistingRegisterRecord = (props: {
    rrId: number;
    currentUser: CurrentUser;
    patient: Patient;
    onCancel: () => void;
}) => {
    const { rrId, patient, currentUser, onCancel } = props;
    return (
        <div className="my-3">
            <NavigateToExistingRecord
                rrId={rrId}
                patient={patient}
                currentUser={currentUser}
                onCancel={onCancel}
                actions={[]}
            />
        </div>
    );
};

/**
 * Används för personnummerfältet i komponentent för att skapa/öppna en översikt.
 */
export const ssnAtom = atom('');

export const SearchAndNavigateToPatient = (props: {
    selectedDiagnos: IPOConfig;
    currentUser: CurrentUser;
    vdname: string;
    isActive: boolean;
    useCreateCheckboxToggle?: boolean;
}): ReactElement => {
    const { selectedDiagnos, currentUser, vdname, isActive, useCreateCheckboxToggle } = props;
    const [ssn, setSsn] = useAtom(ssnAtom);
    const ssnRef = useRef<HTMLInputElement>(null);
    const { isLoading, error, result, exec } = usePromise<{
        patient: Patient;
        record?: RRData;
    }>();
    const inca = useInca();
    const getValueDomainValues = useGetValueDomainValues();

    useEffect(() => {
        const listener = () => {
            unstable_batchedUpdates(() => {
                exec(null);
                setSsn('');
            });
        };

        addIpoEventListener('ipo:overview_closed', listener);

        return () => {
            removeIpoEventListener('ipo:overview_closed', listener);
        };
    }, [setSsn, exec]);

    useEffect(() => {
        if (isActive) {
            ssnRef.current?.select();
            ssnRef.current?.focus();
        }
    }, [isActive, result]); // result är med så att fokus sätts då sökresultat rensats.

    const onCancel = () => {
        exec(null);
    };

    const renderModal = () => {
        const { record, patient } = (result || {});

        if (!record) {
            return (
                <CreateNewOverview
                    className="mb-5"
                    patient={patient!}
                    currentUser={currentUser}
                    diagnos={selectedDiagnos!}
                    onCancel={onCancel}
                    useCreateCheckboxToggle={useCreateCheckboxToggle}
                />
            );
        }

        if (record.bidragande.indexOf(inca.currentUser.position.id) === -1) {
            return (
                <ContributeToOverview
                    className="my-3"
                    patient={patient!}
                    currentUser={currentUser}
                    existingRowId={record.id}
                    existingRegisterRecordId={record.rrId}
                    onCancel={onCancel}
                />
            );
        }

        return <OpenExistingRegisterRecord rrId={record.rrId} currentUser={currentUser} patient={patient!} onCancel={onCancel} />;
    };

    const onSearch = () => {
        exec(async () => {
            return getPatientStatus(ssn, selectedDiagnos!.id, vdname, getValueDomainValues);
        });
    };

    return (
        <div className="mt-3">
            {!result && (
                <div>
                    {selectedDiagnos?.diagnos_varde === 'prostata' && (
                        <Alert>I IPÖ prostata registreras endast uppgifter för patienter med diagnostiserad prostatacancer. När prostatabiopsier tas på män utan känd prostatacancer ska istället registret "Biopsi" väljas vid inloggning.</Alert>
                    )}
                    <div className="mb-3">
                        <SearchBox
                            inputRef={ssnRef}
                            value={ssn}
                            onChange={e => setSsn(e.target.value)}
                            onSearch={onSearch}
                            isSearching={isLoading}
                        />

                        {error && <AjaxErrorAlert error={error} className="mt-3" />}
                    </div>
                </div>
            )}

            {!!result && renderModal()}
        </div>
    );
};
