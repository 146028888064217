import { atom } from 'jotai';
import type { IPOConfig, IPOContextType, OverviewOptions } from '../types';

export const selectedTabAtom = atom<string | null>(null);

/**
 * Atom containing options for the rendered register record.
 */
export const overviewOptionsAtom = atom<OverviewOptions | null>(null);

export const ipoContextAtom = atom<IPOContextType | undefined>(undefined);

export const ipoConfigAtom = atom<IPOConfig | undefined>(undefined);
