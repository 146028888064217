import { Alert } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';

/**
 * Alert som visas då koden byggs med `RCC_ENV=external_demo`. Används på ipo-demo.incanet.se.
 */
export const ExternalDemoAlert = (): ReactElement | null => {
    const isExtDemo = process.env.RCC_ENV === 'external_demo';

    if (!isExtDemo) {
        return null;
    }

    return (
        <Alert bsStyle="warning">
            Detta är en demomiljö av IPÖ med begränsad funktionalitet. Alla eventuella ändringar återställs då sidan laddas om. Observera att personnumret som används är fiktivt och inte tillhör någon verklig person.
        </Alert>
    );
};
