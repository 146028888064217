import './styles.scss';

import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { apiRequest } from '@cancercentrum/inca';
import type { HookDataActions } from '@cancercentrum/rcc-react';
import { AjaxErrorAlert, LoadingAlert, RCCI18n, RegisterDocumentation, RowModel, SubTableModel, useGetValueDomainValues, useIncaForm, usePromise } from '@cancercentrum/rcc-react';
import { createStore, Provider } from 'jotai';
import set from 'lodash/set';
import { ImportantAlertBanner } from '../components/ImportantAlertBanner';
import { appSettingsAtom, getAppSettings2 } from '../components/AppSettings';
import type { Oversikt, OversiktRowModel } from '../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { assertPatientContext } from '../utils/patient';
import type { IPOContextType, OverviewOptions } from '../types';
import { RegisterIds } from '../constants';
import { renderOptionsAtom } from '../startpage-oversikt/atoms';
import { isLoginFromExternalSystem } from '../startpage-oversikt/utils';
import { Contributing } from './components/Contributing';
import type { DataSet, PatientPortalIsActiveUser2 } from './types';
import { ipoConfigAtom, ipoContextAtom, overviewOptionsAtom } from './atoms';
import { useSaveAuditLog } from './useSaveAuditLog';
import { useUpdateMessage } from './useUpdateMessage';
import { addIncaActivityLog, getContributingPositions, getIpoLayoutInfo, getSamtycke } from './utils/overview';
import { createRowHook } from './utils';
import { ExternalDemoAlert } from './ExternalDemoAlert';
import { getSourceSystemShortnames } from './utils/row.ts';

const locales = {
    sv: {
        validation: {
            minLimit: 'Angivet värde är lägre än gränsvärdet %{value}.',
            maxLimit: 'Angivet värde är högre än gränsvärdet %{value}.',
        },
    },
};

export const InitializeOverview = (props: {
    row: OversiktRowModel;
    history: OversiktRowModel[];
    dataActions: HookDataActions<Oversikt>;
    options: OverviewOptions;
}) => {
    const {
        row,
        history,
        dataActions,
        options,
    } = props;
    const dispatch = useDispatch();
    const incaForm = useIncaForm();
    const fullDatasetRef = useRef<DataSet | undefined>();
    const { dataRowOwnerPositionId } = incaForm.form.data;
    const { patient: _patient, currentUser, registerRecord: _registerRecord } = incaForm;
    const patient = _patient!;
    const registerRecord = _registerRecord!;
    const getValueDomainValues = useGetValueDomainValues();
    const env = process.env.RCC_ENV || 'development';
    const { hostname } = location;
    const { isLoading, error, result } = usePromise(async () => {
        assertPatientContext(currentUser, patient);

        const [appSettings, contributingPositions, samtycke, hasPDLInaccessibleChildRecords] = await Promise.all([
            getAppSettings2({
                getValueDomainValues,
                vdname: 'VD_appsettings',
                hostname,
                env,
            }),
            getContributingPositions({
                getValueDomainValues,
                currentUser,
                initialData: incaForm.form.data,
            }),
            getSamtycke({
                getValueDomainValues,
                patient: patient,
            }),
            apiRequest<{ hasPDLInaccessibleChildRecords: boolean }>(`/RegisterRecords/${registerRecord.id}/DesignedForms/Current/HasPDLInaccessibleChildRecords`).then(x => x.hasPDLInaccessibleChildRecords),
        ]);

        let isUserActiveRes: PatientPortalIsActiveUser2 = {
            isActive: false,
            activeNotificationMethods: [],
        };

        try {
            isUserActiveRes = await apiRequest<PatientPortalIsActiveUser2>(`/Patients/${patient.id}/PatientPortal/2/IsActiveUser`);
        } catch {
            // tjänsten kraschar när personnummer inte är giltigt, dvs alla demopatienter utom tolvansson
        }

        const layoutInfo = await getIpoLayoutInfo({
            getValueDomainValues,
            currentUser,
            configId: incaForm.form.data.regvars.diagnoskonfiguration.value as number,
        });

        // Spawna bara iväg anropet.
        addIncaActivityLog(patient.ssn, layoutInfo.config.diagnos);

        return {
            appSettings,
            contributingPositions,
            samtycke,
            layoutInfo,
            patientPortal: isUserActiveRes,
            hasPDLInaccessibleChildRecords,
        };
    });

    fullDatasetRef.current = result?.layoutInfo.fullDataset;

    const ctx = useMemo(() => {
        if (!result) {
            return null;
        }

        const rootPos = result.contributingPositions.find(x => x.id === dataRowOwnerPositionId)!;
        const res: IPOContextType = {
            patient,
            currentUser,
            diagnosis: result.layoutInfo.config,
            dataset: result.layoutInfo.fullDataset,
            samtycke: result.samtycke,
            contributingPositions: result.contributingPositions,
            rootPosition: rootPos,
            patientPortal: result.patientPortal,
            hasPDLInaccessibleChildren: result.hasPDLInaccessibleChildRecords,
            registersWithRegistrationAccess: result.layoutInfo.registersWithReportAccess,
        };

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('DEV >> Generating IPO Context:', res);
        }

        return res;
    }, [result, patient, currentUser, dataRowOwnerPositionId]);

    useUpdateMessage({
        overviewOptions: options,
    });
    useSaveAuditLog();

    useEffect(() => {
        const handler = function (this: RowModel, shortname: string) {
            const { tableName } = this.$rcc;

            if (fullDatasetRef.current) {
                if (!fullDatasetRef.current[tableName as keyof DataSet]) {
                    throw new Error(`Diagnosen har ingen variabel från tabellen "${tableName}".`);
                }

                if (!(fullDatasetRef.current[tableName as keyof DataSet] as any)[shortname]) {
                    throw new Error(`Diagnosen har ingen variabel "${shortname}" från tabellen "${tableName}".`);
                }
            }

            if (process.env.NODE_ENV !== 'production') {
                const query = new URLSearchParams(location.search);

                if (query.get('getUsedRegvars')) {
                    set(global, `usedRegvars.${tableName}.${shortname}`, {});
                }
            }
        };

        // Register a hook so getRegvar, setRegvarValue etc. throws if the shortname isn't in the dataset.
        RowModel.hooks.validateRegvarShortname.add(handler);

        const sourceSystemHook = (r: RowModel) => {
            let res = r;
            const [name, id] = getSourceSystemShortnames(res.$rcc.tableMetadata);

            // Om sourceSystem-variablerna finns men saknar värde ska de defaulta till inca och en uuid.
            if (name && id && !res.getRegvarValue(name) && !res.getRegvarValue(id)) {
                const uuid = crypto.randomUUID();
                // console.log(`[dbg] updating ${res.$rcc.tableName}#${res.getId()}: ${name} = inca, ${id} = ${uuid}`);
                res = res
                    .setRegvarValue(name, 'inca')
                    .setRegvarValue(id, uuid);
            }

            return res;
        };

        const setUuidHook = (r: RowModel) => {
            const metadata = r.$rcc.tableMetadata;

            return [
                'Stral_uuid',
                'Biv_uuid',
            ].reduce((acc, shortname) => {
                if (shortname in metadata.regvars && !acc.getRegvarValue(shortname)) {
                    const uuid = crypto.randomUUID();
                    // console.log(`[dbg] updating ${r.$rcc.tableName}#${r.getId()}: ${shortname} = ${uuid}`);
                    return acc.setRegvarValue(shortname, uuid);
                }

                return acc;
            }, r);
        };

        const createRowHandler = (r: RowModel) => createRowHook(r, currentUser);
        SubTableModel.hooks.createRow.add(createRowHandler);
        SubTableModel.hooks.createRow.add(sourceSystemHook);
        SubTableModel.hooks.createRow.add(setUuidHook);

        return () => {
            RowModel.hooks.validateRegvarShortname.remove(handler);
            SubTableModel.hooks.createRow.remove(setUuidHook);
            SubTableModel.hooks.createRow.remove(sourceSystemHook);
            SubTableModel.hooks.createRow.remove(createRowHandler);
        };
    }, [fullDatasetRef, currentUser]);

    const store = useMemo(() => {
        const res = createStore();
        if (!result || !ctx) {
            return undefined;
        }

        res.set(overviewOptionsAtom, options);
        res.set(appSettingsAtom, result.appSettings);
        res.set(ipoContextAtom, ctx);
        res.set(ipoConfigAtom, ctx.diagnosis);
        res.set(renderOptionsAtom, {
            loginFromExternalSystem: isLoginFromExternalSystem(),
        });

        return res;
    }, [ctx, result, options]);

    if (error) {
        return <AjaxErrorAlert error={error} />;
    }

    if (isLoading || !result || !ctx) {
        return <LoadingAlert />;
    }

    const { layoutInfo } = result;
    const Diagnosis = layoutInfo.configComponent;

    return (
        <Provider store={store}>
            <RegisterDocumentation registerId={RegisterIds.IncaOversikt}>
                <div>
                    <ExternalDemoAlert />
                    <RCCI18n localePhrases={locales}>
                        <Contributing row={row} patient={patient}>
                            <div data-testid="DiagnosisContentWrapper">
                                <ImportantAlertBanner />
                                <Diagnosis
                                    patient={patient}
                                    settings={layoutInfo.config.layout.settings}
                                    layout={layoutInfo.config}
                                    row={row}
                                    history={history}
                                    dataActions={dataActions}

                                    dispatch={dispatch}
                                />
                            </div>
                        </Contributing>
                    </RCCI18n>
                </div>
            </RegisterDocumentation>
        </Provider>
    );
};
