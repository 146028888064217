import type { CurrentUser, Patient } from '@cancercentrum/inca';
import { apiRequest, incaAccessor } from '@cancercentrum/inca';
import { AjaxErrorAlert, Personuppgifter, usePromise } from '@cancercentrum/rcc-react';
import type { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button } from '@cancercentrum/rcc-bootstrap';
import { assertPatientContext } from '../../utils/patient';
import type { OverviewOptions, PdlBlock } from '../../types';
import { dispatchIpoEvent } from '../../utils/events';
import { useAppSettings } from '../AppSettings';
import type { NavigateToRegisterRecordOptions } from './utils';

interface PDLResponse {
    requiresActiveChoice: boolean;
    requiresAggregatedPatientRecord: boolean;
}

/**
 * Visar information kring spärren som finns. Texterna är kopierade från INCA:s inbyggda texter för att efterlikna dem så mycket som möjligt.
 * INCA:s texter kan man komma åt genom att försöka öppna en spärrad registerpost (/RegisterRecords/Details/:id).
 */
const BlockedInfoSection = (props: {
    blocks: PdlBlock[];
}) => {
    const { blocks } = props;

    if (!blocks.some(x => x.isInCareGiver)) {
        return (
            <div>
                <h3>Åtkomst till information</h3>
                <p>Klinikens information är spärrad och ligger i en annan vårdgivare. Hävning av spärr kan endast utföras inom samma vårdgivare.</p>

                <p className="mb-0">Följande enhet har aktiv spärr för informationen:</p>
                <ul>
                    {blocks.map(x => (
                        <li key={x.careUnitHsaId}>{x.position?.fullNameWithCode || 'Kliniken saknas på INCA'} | {x.careUnitHsaId}</li>
                    ))}
                </ul>
            </div>
        );
    }

    return (
        <div>
            <h3>Åtkomst till information</h3>
            <p>Patientens information är spärrad. För åtkomst till informationen krävs att spärr hävs. Välj ”Avbryt” för att avbryta. Åtkomst till patientens information gäller i 24 timmar.</p>

            <p className="mb-0">Spärr tillhörande följande enhet kommer att hävas:</p>
            <ul>
                {blocks.map(x => (
                    <li key={x.careUnitHsaId}>{x.position?.fullNameWithCode || 'Kliniken saknas på INCA'} | {x.careUnitHsaId}</li>
                ))}
            </ul>

            <p className="text-danger">Hävning av patientens spärr kommer att loggas.</p>
        </div>
    );
};

export const NavigateToExistingRecord = (props: {
    rrId: number;
    patient: Patient;
    currentUser: CurrentUser;
    onCancel?: () => void;
    actions: (() => Promise<unknown>)[];
    linkOptions?: NavigateToRegisterRecordOptions;
    options?: OverviewOptions;
    onNavigated?: () => void;
}): ReactElement => {
    const { rrId, patient, currentUser, onCancel, actions, linkOptions, onNavigated, options } = props;
    const appSettings = useAppSettings();

    const navigateToRr = async () => {
        // Eventuella actions som ska köras innan posten kan öppnas.
        // Ex lägger bidragandeformuläret till en action som ser till att inloggad anv klinik hamnar i bidragandetabellen.
        await Promise.all(actions.map(x => x()));
        const inca = incaAccessor();

        const qs = new URLSearchParams();

        if (options?.messageId) {
            qs.append('messageId', options.messageId.toString());
        }

        const url = `${inca.getBaseUrl()}RegisterRecord/Details/${rrId}?${qs.toString()}`;

        if (linkOptions?.shiftKey) {
            global.open(url);
        } else if (linkOptions?.ctrlKey) {
            global.open(url, '_blank');
        } else if (appSettings.open_on_startpage) {
            dispatchIpoEvent('ipo:open_overview', { registerRecordId: rrId, options: options || {} });
        } else {
            (window.top || window).location.href = url;
        }

        onNavigated?.();
    };

    const { isLoading, error, result } = usePromise(async () => {
        assertPatientContext(currentUser, patient);

        const { hasActiveChoice } = await apiRequest<{
            hasActiveChoice: boolean;
        }>(`/Users/Current/Patients/${patient.id}/ActiveChoice`);

        if (!hasActiveChoice) {
            await apiRequest(`/Users/Current/Patients/${patient.id}/ActiveChoice`, {
                method: 'PUT',
                json: { hasActiveChoice: true },
            });
        }

        // Hämta om patienten lämnat samtycke till sammanhållen journalföring.
        const { requiresAggregatedPatientRecord } = await apiRequest<PDLResponse>(`/RegisterRecords/${rrId}/PDL`);

        // Om samtycke saknas kan det vara så att roten skapats av en annan vårdgivare. Om så är fallet är det inte möjligt att gå vidare utan att patienten
        // lämnar sitt samtycke.
        if (requiresAggregatedPatientRecord) {
            throw new Error('Patienten samtycker inte till sammanhållen journalföring och din vårdgivare saknar därför nödvändiga behörigheter. Om patienten ändrar sig kan den tidigare vårdgivaren ändra samtycke. Din klinik kan också ändra via menyvalet Inrapportering - Sök/registrera.');
        }

        const blocks = await apiRequest<PdlBlock[]>(`/RegisterRecords/${rrId}/Blocks`);

        // Om inga spärrar finns kan posten öppnas direkt.
        if (!blocks.length) {
            await navigateToRr();
        }

        return blocks;
    });
    const havSparrPromise = usePromise();

    if (error) {
        return (
            <div>
                <AjaxErrorAlert error={error} />
                {!!onCancel && <Button bsStyle="link" onClick={onCancel}>Avbryt</Button>}
            </div>
        );
    }

    if (isLoading || !result) {
        return (
            <Alert icon="none">
                <FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />
                Förbereder att öppna översikten, var god vänta...
            </Alert>
        );
    }

    if (!result.length) {
        return (
            <Alert icon="none">
                <FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />
                Denna patient finns redan i registret. Öppnar översikt...
            </Alert>
        );
    }

    const onSubmit = () => {
        havSparrPromise.exec(async () => {
            const res = await Promise.all(result.filter(x => x.position).map(x => {
                return apiRequest(`/Users/Current/Patients/${patient.id}/RevokeBlock`, {
                    method: 'POST',
                    json: {
                        positionId: x.position!.id,
                    },
                });
            }));

            await navigateToRr();

            return res;
        });
    };

    // Endast spärrar inom samma vårdgivare går att häva. Således går det endast att öppna så länge ingen annan
    // vårdgivare har några spärrar.
    const canRevokeAllBlocks = result.every(x => x.isInCareGiver);

    return (
        <div>
            <Personuppgifter patient={patient} />

            <BlockedInfoSection blocks={result} />

            <div className="my-3">
                {!!onCancel && <Button bsStyle="link" onClick={onCancel} disabled={havSparrPromise.isLoading}>Avbryt</Button>}

                {canRevokeAllBlocks && (
                    <Button
                        bsStyle="primary"
                        onClick={onSubmit}
                        disabled={havSparrPromise.isLoading}
                    >
                        <FontAwesomeIcon icon={havSparrPromise.isLoading ? faSpinner : faUnlock} spin={havSparrPromise.isLoading} fixedWidth={true} className="me-2" />
                        Häv spärr
                    </Button>
                )}
            </div>

            {havSparrPromise.error && <AjaxErrorAlert error={havSparrPromise.error} />}
        </div>
    );
};
