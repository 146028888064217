import { useAtomValue } from 'jotai';
import type { IPOContextType } from '../../types';
import { ipoContextAtom } from '../atoms';

export const useIPO = (): IPOContextType => {
    const conf = useAtomValue(ipoContextAtom);
    if (!conf) {
        throw new Error('IPOContext must be initialized.');
    }

    return conf;
};
