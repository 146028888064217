import '../polyfill';
import '../oversikt.scss';
import '../offline/registerrecord';
import '../components/versionPrinter';
import '../set-webpack-public-path';

import { createRoot } from 'react-dom/client';
import { DebugBanner, ErrorBoundary, ScopedRegisterRecord } from '@cancercentrum/rcc-react';
import { incaAccessor } from '@cancercentrum/inca';
import type { OverviewOptions } from '../types';
import type { OversiktRowModel } from '../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { Footer } from '../components/Footer';
import { NewPatientOverview } from './NewErrand';
import { DevelopmentConfigurationSelector } from './DevelopmentConfigurationSelector';
import { InitializeOverview } from './InitializeOverview';
import { createOverviewStore, createOverviewValidator } from './utils/overview';
import { FormGlobalIncaProxy } from './FormGlobalIncaProxy';

const inca = incaAccessor();
const domNode = document.getElementById('Inca')!;

if (inca.userDefined?.registerEvents) {
    inca.userDefined.registerEvents(inca);
}

const root = createRoot(domNode);

if (inca.errand) {
    root.render(<NewPatientOverview />);
} else {
    const query = new URLSearchParams((window.top || window).location.search);
    const options: OverviewOptions = {
        messageId: Number.parseInt(query.get('messageId') || '', 10) || undefined,
    };

    root.render((
        // @ts-ignore kassa typings
        <ErrorBoundary>
            <div>
                <DevelopmentConfigurationSelector />
                <DebugBanner />

                <ScopedRegisterRecord
                    registerRecordId={inca.form.data.registerRecordId!}
                    createStore={createOverviewStore}
                    createValidator={createOverviewValidator}
                >
                    {data => (
                        <FormGlobalIncaProxy>
                            <InitializeOverview
                                row={data.row as OversiktRowModel}
                                history={data.history as OversiktRowModel[]}
                                dataActions={data.dataActions}
                                options={options}
                            />
                        </FormGlobalIncaProxy>
                    )}
                </ScopedRegisterRecord>
                <Footer />
            </div>
        </ErrorBoundary>
    ));
}
