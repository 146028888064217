import { format } from 'date-fns';
import invariant from 'invariant';
import type { CurrentUser, DesignedForm, DesignedFormMetadata, Errand, FormData, Patient, RegvarListMetadata } from '@cancercentrum/inca';
import { apiRequest, errandUtils, formUtils, incaAccessor } from '@cancercentrum/inca';
import { dataUtils } from '@cancercentrum/rcc-react';
import { assertPatientContext } from '../../utils/patient';

const RegisterShortname = 'ipo';
const FormShortname = 'standard_form';
const ActionShortname = 'spara';

const getServerDate = (): Promise<Date> => {
    const inca = incaAccessor();

    return new Promise<Date>((resolve) => {
        inca.scriptSupport.getServerDate(date => {
            resolve(date);
        });
    });
};

export const createNewOverview = async (patientId: number, diagnosId: number, currentUser: CurrentUser, auditLogComment: string) => {
    const patient = await apiRequest<Patient>(`/Patients/${patientId}`);

    assertPatientContext(currentUser, patient);

    return errandUtils.createErrandAndExecuteActionByShortname(RegisterShortname, FormShortname, patientId, ActionShortname, {
        onBeforeExecute: async (errand, df, metadata) => {
            const now = await getServerDate();

            errand.data.regvars.diagnoskonfiguration.value = diagnosId;
            errand.data.regvars.skapadDatum.value = format(now, 'yyyy-MM-dd HH:mm:ss');

            const bidragande = dataUtils.createDataRow('Bidragande', metadata);
            bidragande.regvars.bidr_anvandarnamn.value = currentUser.user.username;
            bidragande.regvars.bidr_datum.value = format(now, 'yyyy-MM-dd');
            bidragande.regvars.bidr_godkannande.value = true;
            bidragande.regvars.bidr_kommentar.value = null;
            bidragande.regvars.bidr_position_fk.value = currentUser.position.id;
            errand.data.subTables.Bidragande.push(bidragande);

            const logEntry = dataUtils.createDataRow('AuditLog', metadata);
            logEntry.regvars.al_klientdatum.value = format(now, 'yyyy-MM-dd HH:mm:ss');
            logEntry.regvars.al_kommentar.value = auditLogComment;
            logEntry.regvars.al_plattform.value = navigator.platform;
            logEntry.regvars.al_useragent.value = navigator.userAgent;
            logEntry.regvars.al_position_vd.value = currentUser.position.id;
            logEntry.regvars.al_roll_vd.value = currentUser.role.id;
            logEntry.regvars.al_anvandarnamn.value = currentUser.user.username;
            errand.data.subTables.AuditLog.push(logEntry);

            errand.data.regvars.registreringsstatus.value = (metadata.Oversikt.regvars.registreringsstatus as RegvarListMetadata).listValues.find(x => x.identifier === 'tom_ipo')!.id;
        },
    });
};

export const addBidragandeAndAuditLogRows = async (patientId: number, rowId: number, comment: string, currentUser: CurrentUser): Promise<unknown> => {
    invariant(patientId, 'patientId is missing.');
    invariant(rowId, 'rowId is missing.');

    const errand = await apiRequest<Errand>('/Errands', {
        method: 'POST',
        json: {
            patientId,
            registerShortName: 'ipo',
            formShortName: 'standard_form',
        },
    });
    const [data, df] = await Promise.all([
        apiRequest<FormData>(`/Errands/${errand.id}/Data`),
        apiRequest<DesignedForm>(`/Errands/${errand.id}/DesignedForm`),
    ]);
    const metadata = await apiRequest<DesignedFormMetadata>(`/DesignedForms/${df.id}/Metadata`);

    if (!metadata.Oversikt) {
        throw new Error('Kunde inte hitta tabellen "Oversikt".');
    }

    // OBS! Kritiskt viktigt att sätta include=false på ALLA root-variabler så de inte råkar nullas ut.
    Object.keys(metadata.Oversikt.regvars).forEach(shortname => {
        data.regvars[shortname].include = false;
    });

    try {
        const now = new Date();

        const bidragande = formUtils.createDataRow('Bidragande', metadata);
        bidragande.regvars.bidr_anvandarnamn.value = currentUser.user.username;
        bidragande.regvars.bidr_datum.value = format(now, 'yyyy-MM-dd');
        bidragande.regvars.bidr_godkannande.value = true;
        bidragande.regvars.bidr_kommentar.value = comment;
        bidragande.regvars.bidr_position_fk.value = currentUser.position.id;
        data.subTables.Bidragande.push(bidragande);

        const logEntry = dataUtils.createDataRow('AuditLog', metadata);
        logEntry.regvars.al_klientdatum.value = format(now, 'yyyy-MM-dd HH:mm:ss');
        logEntry.regvars.al_kommentar.value = 'Bidragande';
        logEntry.regvars.al_plattform.value = navigator.platform;
        logEntry.regvars.al_useragent.value = navigator.userAgent;
        logEntry.regvars.al_position_vd.value = currentUser.position.id;
        logEntry.regvars.al_roll_vd.value = currentUser.role.id;
        logEntry.regvars.al_anvandarnamn.value = currentUser.user.username;
        data.subTables.AuditLog.push(logEntry);

        // Uppdatera befintlig rad.
        data.vdlists.ipo_vd.value = rowId;

        return apiRequest(`/Errands/${errand.id}/Event/ShortName/spara/Execute`, {
            method: 'PUT',
            json: {
                ...errand,
                data,
            },
        });
    } catch (err) {
        const msg = (err as any)?.message || '';

        if (msg === 'Sammanhållen journalföring: Formulärdata innehåller referens till ogiltig eller obehörig registerpost.') {
            throw new Error('Patienten samtycker inte till sammanhållen journalföring och din vårdgivare saknar därför nödvändiga behörigheter. Om patienten ändrar sig kan den tidigare vårdgivaren ändra samtycke. Din klinik kan också ändra via menyvalet Inrapportering - Sök/registrera.');
        }

        throw err;
    }
};

export interface NavigateToRegisterRecordOptions {
    shiftKey?: boolean;
    ctrlKey?: boolean;
    linkTarget?: string;
}
