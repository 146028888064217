import type { CurrentUser, DesignedFormTableMetadata } from '@cancercentrum/inca';
import type { RowModel } from '@cancercentrum/rcc-react';

const R_NAME = /sourceSystemName$/;
const R_ID = /sourceSystemId$/;

export const isRowReadOnly = (row: RowModel, currentUser: CurrentUser): boolean => {
    const posId = currentUser.position.id;

    return !!row.id && row.$extra.dataRowOwnerPositionId !== posId;
};

// Returnerar kortnamn på sourceSystemName och sourceSystemId.
export const getSourceSystemShortnames = (tableMetadata: DesignedFormTableMetadata): [string | undefined, string | undefined] => {
    const regvars = Object.keys(tableMetadata.regvars);
    const name = regvars.find(x => R_NAME.test(x));
    const id = regvars.find(x => R_ID.test(x));

    return [name, id];
};

/**
 * Funktion som ser till att endast rader med någon ändrad variabel valideras.
 */
export const validationPredicate = (row: RowModel): boolean => {
    const regvars = Object.keys(row.$rcc.tableMetadata.regvars);
    const isDirty = regvars.some(x => row.getIn(['regvars', x, '$isDirty']));

    return !row.id || isDirty;
};
